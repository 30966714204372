﻿/*
 *  Document   : theme-white.scss
 *  Author     : RedStar Template
 *  Description: This scss file for white theme style classes
 */
 @import "_theme-color-variables";

 .theme-white {
   .navbar {
     background-color: transparent;
     .icon-color {
       color: #484c72;
     }
     .navbar-nav.navbar-right .user_profile span {
       color: #484c72;
     }
   }
   .navbar.active {
     background-color:white !important;
     box-shadow: 3px 0 10px 0 rgba(183, 192, 206, 0.2);
     .nav {
       > li {
         > a {
           color: #434d5a;
         }
       }
     }
     .collapse-menu-icon .mat-icon {
       color: #434d5a;
     }
     .nav-notification-icons .mat-icon {
       color: #434d5a;
     }
   }
 
   .navbar-brand {
     color: $theme-white-navbar-brand;
 
     &:hover {
       color: $theme-white-navbar-brand_hover;
     }
 
     &:active {
       color: $theme-white-navbar-brand_active;
     }
 
     &:focus {
       color: $theme-white-navbar-brand_focus;
     }
   }
 
   .nav {
     > li {
       > a {
         &:hover {
           background-color: $theme-white-nav-anchor_hover;
           text-decoration: none;
         }
 
         &:focus {
           background-color: $theme-white-nav-anchor_focus;
           text-decoration: none;
         }
       }
     }
 
     .open {
       > a {
         background-color: $theme-white-nav-anchor-opened;
 
         &:hover {
           background-color: $theme-white-nav-anchor-opened_hover;
         }
 
         &:focus {
           background-color: $theme-white-nav-anchor-opened_focus;
         }
       }
     }
   }
 
   .bars {
     color: $theme-white-bar;
   }
 
   .sidebar {
     .menu {
       .list {
         li {
           &.active {
             background-color: $theme-white-menu-list-active;
           }
           a {
             -moz-transition: all 0.3s;
             -o-transition: all 0.3s;
             -webkit-transition: all 0.3s;
             transition: all 0.3s;
             i,
             span {
               -moz-transition: all 0.3s;
               -o-transition: all 0.3s;
               -webkit-transition: all 0.3s;
               transition: all 0.3s;
             }
           }
         }
 
         .ml-menu {
           background-color: $theme-white-menu-list-submenu;
           color: white;
 
           // li.active a:not(.menu-toggle):before {
           //   content: "\f068";
           //   font-family: "Font Awesome 5 Free";
           //   font-size: 11px;
           //   display: block;
           //   width: 7px;
           //   height: 7px;
           //   position: absolute;
           //   left: 10%;
           //   font-weight: 900;
           // }
         }
       }
     }
 
     .legal {
       background-color: $theme-white-legal-bg;
 
       .copyright {
         a {
           color: $theme-white !important;
         }
       }
     }
   }
 
   .breadcrumb li a {
     color: $theme-white-active-color !important;
   }
   .page-item.active .page-link {
     background-color: $theme-white-active-color;
     border-color: $theme-white-active-color;
     border-radius: 50%;
     margin: 5px;
     box-shadow: 0 4px 5px 0 #d4d8da, 0 1px 10px 0 #d4d8da,
       0 2px 4px -1px #d4d8da;
     padding: 0px 12px;
     min-width: 30px;
     line-height: 30px;
     color: #ffffff;
     text-transform: uppercase;
   }
 
   .btn-primary {
     background-color: $theme-white-active-color !important;
     color: #fff !important;
     border-color: $theme-white-active-color !important;
     &:hover {
       background-color: $theme-white-active-color !important;
       color: #fff !important;
     }
     &:active {
       background-color: $theme-white-active-color !important;
       color: #fff !important;
     }
     &:focus {
       background-color: $theme-white-active-color !important;
       color: #fff !important;
     }
     &:disabled {
       background-color: $theme-white-active-color !important;
       color: #fff !important;
     }
   }
   .btn-outline-primary {
     background: 0 0 !important;
     color: $theme-white-bar !important;
     border: 1px solid $theme-white-active-color !important;
     &:hover {
       //background: $theme-white-active-color !important;
       //color: #fff !important;
      // border: 1px solid $theme-white !important;

      // background: #fff !important;
      // color: #fff !important;
      // border: 1px solid #00bcd4 !important;
      background: #00bcd4 !important;
      color: #fff !important;
      border: 1px solid #fff !important;
      
     }
   }
   .timelineLabelColor strong {
     color: $theme-white-active-color !important;
   }
   .top-sidebar {
     .horizontal-menu {
       li {
         &.active {
           > a {
             color: $theme-white-active-color;
           }
         }
         a {
           -moz-transition: all 0.3s;
           -o-transition: all 0.3s;
           -webkit-transition: all 0.3s;
           transition: all 0.3s;
           &:hover {
             color: $theme-white-active-color;
           }
         }
       }
     }
   }
   .demo-choose-skin li.actived:after {
     color: #000;
   }
 
   .nav-tabs > li > a:before {
     border-bottom: 2px solid $theme-white-active-color;
   }
   .search-box input#search {
     color: gray;
     background: #edecec;
   }
   .settingSidebar ul.choose-theme li.active div::after {
     color: #000;
   }
 }
 /*Logo Header Background Color*/
 .logo-white {
   .navbar-header {
     background-color: $theme-white-blue;
 
     .logo-name {
       color: #000;
     }
   }
 }
 